import React, { ChangeEvent, useRef, useState } from 'react'
import './SearchInput.scss'

interface SearchInputProps {
  onSearch: (searchTerm: string) => void
  placeholder: string
  searchType?: string
  searchRequest?: (searchData: {
    firstName: string | undefined
    lastName: string | undefined
  }) => void
  searchOptionRequest?: boolean
  searchReturnResult?: boolean
  cancelSearch?: () => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  placeholder,
  searchType,
  searchRequest,
  searchOptionRequest,
  searchReturnResult,
  cancelSearch,
}) => {
  const [searchOption, setSearchOption] = useState(1)
  const [searchData, setSearchData] = useState<{
    firstName: string | undefined
    lastName: string | undefined
  }>({ firstName: undefined, lastName: undefined })

  const handleSearchType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSearchOption(+e.target.value)
  }

  const handleInputData = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const inputFirstName = useRef<HTMLInputElement>(null)
  const inputLastName = useRef<HTMLInputElement>(null)

  const inputOnePrep = () => {
    if (inputFirstName.current) {
      inputFirstName.current.value = ''
    }

    cancelSearch && cancelSearch()
  }

  const inputTwoPrep = () => {
    if (inputLastName.current) {
      inputLastName.current.value = ''
    }

    cancelSearch && cancelSearch()
  }

  return (
    <div className='searchInput'>
      {searchType && searchType === 'user' && (
        <select className='selectOption form-control' onChange={handleSearchType}>
          <option value={1}>Type</option>
          <option value={1}>Search by msisdn</option>
          <option value={2}>Search by name</option>
        </select>
      )}
      {((searchType && searchType !== 'user') || searchOption === 1) && (
        <div className='searchInput-inner'>
          <span>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11.675 11.5661C9.34155 13.8995 5.55835 13.8995 3.22493 11.5661C0.891523 9.23269 0.891523 5.44948 3.22493 3.11607C5.55835 0.782656 9.34156 0.782656 11.675 3.11607C14.0084 5.44948 14.0084 9.23269 11.675 11.5661ZM11.675 11.5661L16.4126 16.3037'
                stroke='#868484'
                strokeWidth='1.5'
                strokeLinecap='round'
              />
            </svg>
          </span>

          <input
            className='form-control'
            type='text'
            placeholder={placeholder}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      )}

      {((searchType && searchType !== 'user') || searchOption === 2) && (
        <div className='searchInput-inner'>
          <form>
            <div className='container'>
              <div className='row'>
                <div className='col-md-5'>
                  <div className='input-contain'>
                    {searchReturnResult && (
                      <button className='btn p-0 border-0' type='reset' onClick={inputOnePrep}>
                        <i className='d-icon d-close'></i>
                      </button>
                    )}
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder='First name'
                      ref={inputFirstName}
                      name='firstName'
                      onChange={handleInputData}
                    />
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='input-contain'>
                    {searchReturnResult && (
                      <button className='btn p-1 border-0' type='reset' onClick={inputTwoPrep}>
                        <i className='d-icon d-close'></i>
                      </button>
                    )}
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder='Last name (optional)'
                      ref={inputLastName}
                      name='lastName'
                      onChange={handleInputData}
                    />
                  </div>
                </div>
                <div className='col-md-2'>
                  <button
                    className='btn btn-default'
                    type='button'
                    disabled={searchOptionRequest}
                    onClick={() => {
                      searchRequest && searchRequest(searchData)
                    }}
                  >
                    {!searchOptionRequest ? (
                      <i className='d-icon d-search'></i>
                    ) : (
                      <span className='spinner-border spinner-border-sm'></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default SearchInput
