import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'
import { activateTGPDataType } from '@/Pages/Frontend/Components/TGPlus/CreateSubscription/CreateSubscription'
import { createSolarProviderType } from '@/Pages/Frontend/Components/TGPlus/CreateSubscription/CreateSolarProvider'

AxiosInterceptor()

const tradegridPlusService = {
  async index(pageNumber: number, sortOrder: string, status: string) {
    return axios.get(
      `/tradegridplus?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`,
    )
  },

  search: async (endpoint: string, pageNumber: number, sortOrder: string, status: string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}&status=${status}`
    return await axios.get(`/tradegridplus?${queryParams}`)
  },

  subscriptionTransaction: async (id: string | undefined, pageNumber: number, owner: string) => {
    return await axios.get(`/tradegridplus/${id}?pageNumber=${pageNumber}&owner=${owner}`)
  },

  async solarProviders(pageNumber: number, sortOrder: string, status: string) {
    return await axios.get(
      `/tradegridplus/solarProvider/account?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`,
    )
  },

  createSolarProvider: async (data: createSolarProviderType) => {
    return await axios.post('/tradegridplus/solarProvider/account', data)
  },

  async solarProviderStations(
    pageNumber: number,
    sortOrder: string,
    status: string,
    customerMsisdn: string | undefined,
  ) {
    return axios.get(
      `/tradegridplus?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&solarProviderAccountId=${customerMsisdn}`,
    )
  },
  //

  async users(pageNumber: number, sortOrder: string) {
    return axios.get(`/tradegridplus/users/all?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },

  async stations(
    pageNumber: number,
    sortOrder: string,
    status: string,
    customerMsisdn: string | undefined,
  ) {
    return axios.get(
      `/tradegridplus?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&customerMsisdn=${encodeURIComponent(customerMsisdn ?? '')}`,
    )
  },

  async userReferal(pageNumber: number, userUuid: string | undefined) {
    return axios.get(`/tradegridplus/users?pageNumber=${pageNumber}&referredByUserId=${userUuid}`)
  },

  async single(pageNumber: number, id: string | undefined) {
    return axios.get(`/tradegridplus?pageNumber=${pageNumber}&id=${id}`)
  },

  async approveSubscription(status: string, id: string | undefined) {
    return axios.put(`/tradegridplus/${id}/approval/${status}`)
  },

  async updateSubscription(
    data: { solarPowerCapacity: string; monthlySubscriptionMajor: number },
    id: string | undefined,
  ) {
    return axios.put(`/tradegridplus/${id}/updateInstallationDetails`, data)
  },

  async subscriptionInstallationCompleted(
    data: { solarPowerCapacity: string | undefined; monthlySubscriptionMajor: number | undefined },
    id: string | undefined,
  ) {
    return axios.put(`/tradegridplus/${id}/installationComplete`, data)
  },
  activateTGPlus: async (data: activateTGPDataType) => {
    return await axios.post('/tradegridplus', data)
  },

  widthdrawWallet: async (data: {
    amountMajor: number
    solarProviderAccountId: string | undefined
  }) => {
    return await axios.post('/tradegridplus/solarProvider/account/withdraw', data)
  },
}
export default tradegridPlusService
