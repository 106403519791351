import React, { Fragment, Key, useEffect, useState } from 'react'
import './Transactions.scss'
import { Logger } from '../../../../Helpers/Logger'
import transactionService from '../../../../Services/transactionService'
import { TimeConverter } from '../../../../Helpers/TimeConverter'
import { HorizontalLoader } from '../../../../Components'
import { TransactionType } from '../../../../Models/trasactionType'
import ModalWrapper from '../../../../Components/ModalWrapper/ModalWrapper'
import AddTransaction from '../../Components/Transactions/AddTransaction/AddTransaction'
import Pagination from '../../../../Components/Pagination/Pagination'
import SearchInput from '../../../../Components/SearchInput/SearchInput'
import { checkSearchTransactionInputType } from '../../../../Helpers'
import { Link } from 'react-router-dom'
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter'
import Swal from 'sweetalert2'
import { AddTransactionOption, InternalTransferTransaction } from '../../Components'

const Transactions = () => {
  const [tab, setTab] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [sortOrder, setSortOrder] = useState('DESC')
  const [transactionFlowType, setTransactionFlowType] = useState<string>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [status, setStatus] = useState('ALL')

  const [order, setOrder] = useState([])
  const [loading, setLoading] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const [searching, setSearching] = useState(false)
  //    const { showAlert } = ShowTGAlert()

  const getTransaction = async () => {
    setLoading(true)

    setSearching(true)
    // Check if a search operation is already in progress
    if (searching) {
      return
    }

    await transactionService.index(pageNumber, sortOrder, status, transactionFlowType).then(
      (response) => {
        setLoading(false)
        setOrder(response?.data.data.dataset)
        console.log('response transactionService', response)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })

        setSearching(false)
      },
      (error: { response: object }) => {
        setLoading(false)
        setSearching(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    getTransaction()
  }, [sortOrder, transactionFlowType, tab, pageNumber])

  const [showModal, setShowModal] = useState(false)
  const toggleModal = (option?: number) => {
    setShowModal((prevState) => !prevState)

    if (option) {
      if (option === 1) {
        toggleAddModal()
      } else {
        toggleTransferModal()
      }
    }
  }

  const [showAddModal, setShowAddModal] = useState(false)
  const toggleAddModal = () => {
    setShowAddModal((prevState) => !prevState)
  }

  const [showTransferModal, setShowTransferModal] = useState(false)

  const toggleTransferModal = () => {
    setShowTransferModal((prevState) => !prevState)
  }

  const handleSearch = async (searchTerm: string) => {
    // Check if a search operation is already in progress
    if (searching) {
      return
    }

    // Set the searching flag to true
    setSearching(true)

    // Perform search operation using the searchTerm
    console.log('Searching for:', searchTerm)
    const searchTermRes = checkSearchTransactionInputType(searchTerm)

    let endpoint = ''
    if (searchTermRes === 'paystackReference') {
      endpoint = `paystackReference=${encodeURIComponent(searchTerm)}`
    } else if (searchTermRes === 'PhoneNumber') {
      endpoint = `customerMsisdn=${encodeURIComponent(searchTerm)}`
    } else if (searchTermRes === 'AlphaNumeric') {
      endpoint = `customerAccountId=${encodeURIComponent(searchTerm)}`
    }

    if (endpoint === '') {
      getTransaction()
      setSearching(false)
      return
    }

    setLoading(true)
    await transactionService.searchTransaction(endpoint, pageNumber, sortOrder).then(
      (response: {
        data: {
          data: {
            dataset: React.SetStateAction<never[]>
            pageNumber: number
            pageSize: number
            total: number
          }
        }
      }) => {
        setLoading(false)
        setOrder(response?.data.data.dataset)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
        setSearching(false) // Set searching flag to false
      },
      (error: { response: { data: { error: string } } }) => {
        setLoading(false)
        setSearching(false)
        setOrder([])
        getTransaction()
        // setTimeout(()=>{
        //     searching===false && getTransaction()
        // }, 3000)
        // Set searching flag to false
        // showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})
        Logger('response error', error.response)
      },
    )
  }

  const exportTransactions = async (docType: string) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    Swal.fire({
      text: 'Exporting, please wait a moment.',
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })

    await transactionService.exportTransaction(pageNumber, sortOrder, transactionFlowType).then(
      (response) => {
        const streamedData = new Blob([response.data], { type: docType })
        const streamedUrl = window.URL.createObjectURL(streamedData)

        a.href = streamedUrl
        a.download = 'transctions'
        a.click()
        window.URL.revokeObjectURL(streamedUrl)
        Swal.close()
      },
      (error) => {
        Swal.close()
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 1500,
        })
        console.log('exportTransactions error', error)
      },
    )
  }

  return (
    <div className='Transactions'>
      <div className='container mt-4'>
        <div className='card border-0'>
          <div className='card-header border-0'>
            <div className='row'>
              <div className='col-12 col-md-8 my-auto'>
                <div className='title'>All Transactions</div>
              </div>

              <Fragment>
                <div className='col-9 col-md-4  text-md-end'>
                  <button className='btn btn-respond-req' onClick={() => setShowModal(true)}>
                    <svg
                      width='18'
                      height='19'
                      viewBox='0 0 18 19'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.5 10.0625H4.5C4.1925 10.0625 3.9375 9.8075 3.9375 9.5C3.9375 9.1925 4.1925 8.9375 4.5 8.9375H13.5C13.8075 8.9375 14.0625 9.1925 14.0625 9.5C14.0625 9.8075 13.8075 10.0625 13.5 10.0625Z'
                        fill='white'
                      />
                      <path
                        d='M9 14.5625C8.6925 14.5625 8.4375 14.3075 8.4375 14V5C8.4375 4.6925 8.6925 4.4375 9 4.4375C9.3075 4.4375 9.5625 4.6925 9.5625 5V14C9.5625 14.3075 9.3075 14.5625 9 14.5625Z'
                        fill='white'
                      />
                    </svg>
                    Upload Payment
                  </button>
                </div>
              </Fragment>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-2'>
            <div className='col-12 col-md-8'>
              <div className='head-tab'>
                <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'
                      onClick={() => setTab(0)}
                    >
                      Customer Transaction
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'
                      onClick={() => setTab(1)}
                    >
                      Admin Created Transactions
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='card border-0'>
            <div className='card-header border-0 px-0'>
              <div className='row mt-2'>
                <div className='col-12 col-md-8'>
                  <SearchInput
                    onSearch={handleSearch}
                    placeholder={'Search user msidn, Payment Refenece or user ID'}
                  ></SearchInput>
                </div>
                <div className='col-6 col-md-2 dropdown'>
                  <button
                    className='btn btn-filter dropdown-toggle'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <svg
                      width='17'
                      height='17'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z'
                        fill='#868484'
                      />
                      <path
                        d='M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z'
                        fill='#868484'
                      />
                    </svg>
                    Filters
                  </button>
                  <div className='dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown'>
                    <div className='dropdown-item'>
                      <form action=''>
                        <div className='form-group'>
                          <label htmlFor=''>Sort List</label>
                          <select
                            id=''
                            className='form-control'
                            onChange={(e) => {
                              setSortOrder(e.target.value)
                            }}
                          >
                            <option value='DESC'>Descending Order</option>
                            <option value='ASC'>Ascending Order</option>
                          </select>
                        </div>
                        <div className='form-group'>
                          <label htmlFor=''>Type</label>
                          <select
                            id=''
                            className='form-control'
                            onChange={(e) => {
                              setTransactionFlowType(e.target.value)
                            }}
                          >
                            <option value='IN'>Credit</option>
                            <option value='OUT'>Debit</option>
                          </select>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-md-6'>
                            <button
                              type='button'
                              className='btn btn-reset'
                              onClick={() => setSortOrder('DESC')}
                            >
                              Reset
                            </button>
                          </div>
                          <div className='col-md-6 text-end'>
                            <button
                              type='button'
                              className='btn btn-filter'
                              onClick={() => setSortOrder('DESC')}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-2 dropdown'>
                  <button
                    className='btn btn-export dropdown-toggle'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <svg
                      width='17'
                      height='17'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9 11V17L11 15'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9 17L7 15'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                        stroke='#868484'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>{' '}
                    Export
                  </button>

                  <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                    {/* <li className='dropdown-item' onClick={()=>exportTransactions('application/pdf')}> Export as .pdf</li>  */}
                    <li className='dropdown-item' onClick={() => exportTransactions('text/csv')}>
                      {' '}
                      Export as .csv
                    </li>
                    {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/doc')}> Export as .doc </li>  */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>Customer</td>
                      <td>Customer Msisdn</td>
                      <td>Amount</td>
                      <td>Description</td>
                      <td>Created By</td>
                      <td>Type</td>
                      <td>Wallet Bal.</td>
                      <td>Date</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === true && order.length === 0 && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          <HorizontalLoader></HorizontalLoader>
                        </td>
                      </tr>
                    )}
                    {order.length > 0 &&
                      order.map((data: TransactionType, index: Key) => (
                        <tr key={index}>
                          <td>{Number(index) + 1}</td>
                          <td>
                            {data.customer.firstName} {data.customer.lastName}
                          </td>
                          <td>{data.customer.msisdn}</td>
                          <td>
                            {data.currency}
                            {CurrencyFormatter(data.amountMajor)}
                          </td>
                          <td>{data.description}</td>
                          <td>{data.type}</td>
                          <td
                            className={
                              ['in', 'IN'].includes(data.flowType) ? 'text-success' : 'text-danger'
                            }
                          >
                            {['in', 'IN'].includes(data.flowType) ? 'Credit' : 'Debit'}
                          </td>
                          <td>
                            {data.currency}
                            {CurrencyFormatter(Number(data.walletBalanceMajorAfter))}
                          </td>
                          <td>{TimeConverter(data.createdAt)}</td>
                          <td>
                            <Link to={`/transactions/${data.id}`}>
                              <button className='btn btn-sm btn-transaction-detail'>
                                <i className='d-icon d-eye-open'></i>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}

                    {loading === false && order.length === 0 && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          No transaction found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {paginationData.total >= paginationData.pageSize && (
                <div className='pagination w-100 d-flex justify-content-center'>
                  {paginationData.total && (
                    <Pagination
                      className='pagination-bar ps-0'
                      currentPage={Number(paginationData.pageNumber)}
                      totalCount={paginationData.total}
                      pageSize={paginationData.pageSize}
                      onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper showModal={showModal} closeModal={toggleModal} title='Upload Payment'>
        <AddTransactionOption
          toggleModal={(option) => {
            toggleModal(option)
          }}
        ></AddTransactionOption>
      </ModalWrapper>

      <ModalWrapper showModal={showAddModal} closeModal={toggleAddModal} title='Upload Payment'>
        <AddTransaction toggleModal={toggleAddModal}></AddTransaction>
      </ModalWrapper>

      <ModalWrapper
        showModal={showTransferModal}
        closeModal={toggleTransferModal}
        title='Internal Transfer'
      >
        <InternalTransferTransaction
          toggleModal={toggleTransferModal}
        ></InternalTransferTransaction>
      </ModalWrapper>
    </div>
  )
}

export default Transactions
