import React, { FC, useState } from 'react'
import './CreateSubscription.scss'
import { useForm } from 'react-hook-form'
import tradegridPlusService from 'Services/tradegridPlusService'
import Swal from 'sweetalert2'

export type createSolarProviderType = {
  adminFirstName: 'string'
  adminLastName: 'string'
  adminEmail: 'string'
  adminPhoneNumber: 'string'
  businessName: 'string'
}

interface propsType {
  toggleModal: () => void
}

const CreateSolarProvider: FC<propsType> = ({ toggleModal }) => {
  const [request, setRequest] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<createSolarProviderType>()

  const submit = async (data: createSolarProviderType) => {
    console.log('data submit', data)

    setRequest(true)

    await tradegridPlusService.createSolarProvider(data).then(
      (response) => {
        setRequest(false)
        toggleModal()

        Swal.fire({
          icon: 'success',
          title: 'Done!',
          text: 'Solar provider creaated succesfully',
          showConfirmButton: false,
          timer: 3000,
        })
      },
      (error: { response: { data: { error: string } } }) => {
        console.log('error', error.response)
        setRequest(false)
        Swal.fire({
          icon: 'warning',
          title: 'Oops!',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 3000,
        })
      },
    )
  }

  return (
    <div className='create-subscription'>
      <form onSubmit={handleSubmit(submit)} className='mt-5 mb-5'>
        <div className='form-group mb-3'>
          <label htmlFor='adminFirstName'>Admin First Name</label>
          <input
            type='text'
            id='adminFirstName'
            className={errors.adminFirstName ? 'form-control is-invalid' : 'form-control'}
            {...register('adminFirstName', { required: 'field can not be empty' })}
          />
          {errors.adminFirstName && (
            <div className='error text-danger'>{errors.adminFirstName.message}</div>
          )}
        </div>
        <div className='form-group mb-3'>
          <label htmlFor='adminLastName'>Admin Last Name</label>
          <input
            type='text'
            id='adminLastName'
            className={errors.adminLastName ? 'form-control is-invalid' : 'form-control'}
            {...register('adminLastName', { required: 'field can not be empty' })}
          />
          {errors.adminLastName && (
            <div className='error text-danger'>{errors.adminLastName.message}</div>
          )}
        </div>
        <div className='form-group mb-3'>
          <label htmlFor='adminEmail'>Admin Email</label>
          <input
            type='email'
            id='adminEmail'
            className={errors.adminEmail ? 'form-control is-invalid' : 'form-control'}
            {...register('adminEmail', { required: 'field can not be empty' })}
          />
          {errors.adminEmail && (
            <div className='error text-danger'>{errors.adminEmail.message}</div>
          )}
        </div>
        <div className='form-group mb-3'>
          <label htmlFor='adminPhoneNumber'>Admin Phone Number</label>
          <input
            type='text'
            id='adminPhoneNumber'
            className={errors.adminPhoneNumber ? 'form-control is-invalid' : 'form-control'}
            placeholder='+2348143233387'
            {...register('adminPhoneNumber', { required: 'field can not be empty' })}
          />
          {errors.adminPhoneNumber && (
            <div className='error text-danger'>{errors.adminPhoneNumber.message}</div>
          )}
        </div>
        <div className='form-group mb-3'>
          <label htmlFor='businessName'>Business Name</label>
          <input
            type='text'
            id='businessName'
            className={errors.businessName ? 'form-control is-invalid' : 'form-control'}
            {...register('businessName', { required: 'field can not be empty' })}
          />
          {errors.businessName && (
            <div className='error text-danger'>{errors.businessName.message}</div>
          )}
        </div>
        <div className='form-input mt-3'>
          <button className='btn' type='submit' disabled={request}>
            Submit {request && <span className='spinner-border spinner-border-sm'></span>}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreateSolarProvider
