import './CityLaunchersDetails.scss'
import { BreadCrumb, HorizontalLoader } from '../../../../../Components'
import thumbnail from './../../../../../Assets/Images/thumbnail.jpeg'
import { useParams } from 'react-router-dom'
import { CustomerType } from '../../../../../Models'
import { Key, useEffect, useState } from 'react'
import customerService from '../../../../../Services/customerService'
import { TimeConverter } from '../../../../../Helpers/TimeConverter'
import { CityLauncherMoel } from '@/Models/cityLauncers'

const CityLaunchersDetails = () => {
  const { customerMsisdn } = useParams<{ customerMsisdn: string | undefined }>()
  const [userData, setUserData] = useState<CityLauncherMoel>()
  const [request, setRequest] = useState(false)

  const getUser = async () => {
    setRequest(true)
    await customerService.cityLaunchersDetails(customerMsisdn).then(
      (response) => {
        setUserData(response.data.data.dataset[0])
        console.log('getUser', response.data.data.dataset[0])

        setRequest(false)
      },
      (error: { response: { data: { error: string } } }) => {
        setRequest(false)
        console.log('orderService error', error.response)
      },
    )
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className='CityLaunchersDetails'>
      <div className='container'>
        <BreadCrumb
          title={`#${customerMsisdn}`}
          parent={'City Launchers'}
          parentUrl={'city-launchers'}
          showTitle={false}
        ></BreadCrumb>

        <div className='container mt-2'>
          <div className='row details-section'>
            <div className='col-md-12'>
              <div className='card border-0 mt-3 pt-4'>
                <div className='card-header bg-white border-0'>
                  <div className='container h-100'>
                    <div className='row h-100'>
                      <div className='col-6 col-sm-8 col-md-7 col-xl-10 my-auto'>
                        <div className='title'>Applicant&apos;s Profile</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card-body'>
                  {request && <HorizontalLoader></HorizontalLoader>}
                  {!request && (
                    <div className='row mt-4'>
                      <div className='col-12 col-md-4 text-md-center mb-3'>
                        <div className='profile-image'>
                          <img
                            src={
                              userData?.studentIdCard.url ? userData?.studentIdCard.url : thumbnail
                            }
                            className=''
                          />
                        </div>
                      </div>

                      <div className='col-md-5 text-start'>
                        <div className='profile-item'>
                          <div className='name'>Full Name</div>
                          <div className='value'>
                            {userData?.firstName} {userData?.lastName}
                          </div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>Email</div>
                          <div className='value'>{userData?.email ? userData?.email : '-'}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>Phone Number</div>
                          <div className='value'>{userData?.phoneNumber}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>School</div>
                          <div className='value'>{userData?.school}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>Department</div>
                          <div className='value'>{userData?.department}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>Hostel Address</div>
                          <div className='value'>{userData?.hostelAddress}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>School Address</div>
                          <div className='value'>{userData?.schoolAddress}</div>
                        </div>
                        <br />
                        <div className='profile-item'>
                          <div className='name'>Solutions document</div>
                          <div className='value'>
                            {userData?.solutionDocument ? (
                              <a href={userData?.solutionDocument?.url} download={true}>
                                Download Document
                              </a>
                            ) : (
                              'None'
                            )}
                          </div>
                        </div>
                        <div className='profile-item'>
                          <div className='name'>Time created</div>
                          <div className='value'>{TimeConverter(userData?.createdAt)}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CityLaunchersDetails
