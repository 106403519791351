import React, { Key, useEffect, useState } from 'react'
import './TGPlusSubscription.scss'
import { Logger } from '../../../../Helpers/Logger'
import { TimeConverter } from '../../../../Helpers/TimeConverter'
import { HorizontalLoader, ModalWrapper } from '../../../../Components'
import Pagination from '../../../../Components/Pagination/Pagination'
import SearchInput from '../../../../Components/SearchInput/SearchInput'
import { Link, useNavigate } from 'react-router-dom'
import tradegridPlusService from 'Services/tradegridPlusService'
import { CustomerType, tgplusModel } from 'Models'
import { CreateSolarProvider, CreateSubscription } from '../../Components'
import { checkSearchInputType } from 'Helpers'

// tgplus-stations/:userUuid

const TGPlusSubscription = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSolarNumber, setPageSolarNumber] = useState(1)

  const [userPageNumber, setUserPageNumber] = useState(1)

  const [sortOrder, setSortOrder] = useState('DESC')
  const [sortSolar, setSortSolar] = useState('DESC')

  const [status, setStatus] = useState('ALL')

  const [searching, setSearching] = useState(false)
  const [searchReturnResult, setSearchReturnResult] = useState(false)

  const navigate = useNavigate()

  const [order, setOrder] = useState<CustomerType[]>([])

  const [subscriptions, setSubscriptions] = useState<tgplusModel[]>([])

  const [solarSubscriptions, setSolarSubscriptions] = useState<
    Array<{
      id: string
      businessName: string
      createdAt: string
      balance: string
    }>
  >()

  const [loading, setLoading] = useState(false)
  const [solarLoading, setSolarLoading] = useState(false)

  const [loadingUser, setLoadingUser] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const [solarPaginationData, setSolarPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const [userPaginationData, setUserPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getTGPlusUsers = async () => {
    setLoadingUser(true)

    await tradegridPlusService.users(userPageNumber, sortOrder).then(
      (response) => {
        setLoadingUser(false)
        setOrder(response?.data.data.dataset)

        setUserPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setLoadingUser(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    getTGPlusUsers()
  }, [sortOrder, userPageNumber])

  function formatString(input: string) {
    const lowerCaseString = input.toLowerCase()

    // Remove underscores
    const formattedString = lowerCaseString.replace(/_/g, ' ')

    // capitalize status
    const capitalizedString = formattedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedString
  }

  const [tab, setTab] = useState(0)

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const [show2Modal, setShow2Modal] = useState(false)

  const toggle2Modal = () => {
    setShow2Modal((prevState) => !prevState)
  }

  const getSubscription = async () => {
    setLoading(true)

    await tradegridPlusService.index(pageNumber, sortOrder, status).then(
      (response) => {
        setLoading(false)
        setSubscriptions(response?.data.data.dataset)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setLoading(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    getSubscription()
  }, [sortOrder, pageNumber, status])

  const [searchOptionRequest, setSearchOptionRequest] = useState(false)

  const handleSearch = async (searchTerm: string) => {
    // If a search operation is already in progress, return early
    if (searching) {
      // return;
    }

    // Set the searching flag to true to indicate that a search is starting
    setSearching(true)
    setSearchReturnResult(false)

    // Perform search operation using the searchTerm
    const searchTermRes = checkSearchInputType(searchTerm)

    let endpoint = ''
    if (searchTermRes === 'PhoneNumber') {
      endpoint = `customerMsisdn=${encodeURIComponent(searchTerm)}`
    } else if (searchTermRes === 'AlphaNumeric') {
      endpoint = `customerAccountId=${encodeURIComponent(searchTerm)}`
    } else if (searchTermRes === 'Numeric') {
      endpoint = `customerUserId=${encodeURIComponent(searchTerm)}`
    }

    if (endpoint === '') {
      setLoading(false)
      setSearching(false)
      getSubscription()
      return
    }

    setLoading(true)
    await tradegridPlusService.search(endpoint, pageNumber, sortOrder, status).then(
      (response) => {
        setLoading(false)
        setSearching(false)

        setSubscriptions(response?.data.data.dataset)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })

        if (response?.data.data.dataset.length > 0) {
          setSearchReturnResult(true)
        }
      },
      (error: { response: { data: { error: string } } }) => {
        setSubscriptions([])

        setTimeout(() => {
          setLoading(false)
          setSearching(false)
          if (searchTerm.length < 9) {
            getSubscription()
          }
        }, 3000)

        setLoading(false)
        setSearchReturnResult(false)
        // showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})
        Logger('response error', error.response)
      },
    )
  }

  const searchRequest = async (data: {
    firstName: string | undefined
    lastName: string | undefined
  }) => {
    console.log(data)
  }

  const cancelSearch = () => {
    getSubscription()
  }

  const getSolarProviders = async () => {
    setSolarLoading(true)

    await tradegridPlusService.solarProviders(pageNumber, sortOrder, status).then(
      (response) => {
        setSolarLoading(false)
        setSolarSubscriptions(response?.data.data)

        setSolarPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setSolarLoading(false)
      },
    )
  }

  useEffect(() => {
    getSolarProviders()
  }, [sortSolar, pageSolarNumber])

  const getCountdownStatus = (targetDateStr: string) => {
    const targetDate = new Date(targetDateStr).getTime()
    const now = new Date().getTime()
    const timeLeft = targetDate - now

    if (timeLeft <= 0) {
      return <span style={{ color: 'red', fontWeight: 'bold' }}>Overdue</span>
    }

    const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24))

    return <span>{daysLeft} days left</span>
  }

  return (
    <div className='TGPlusSubscription'>
      <div className='container mt-4'>
        <div className='card border-0 d-lg-none'>
          <div className='card-header border-0'>
            <div className='row'>
              <div className='col-12 col-md-8 my-auto'>
                <div className='title'>All Tradegrid Plus Subscription</div>
              </div>
              {/* <div className='col-md-4 text-md-end'>
                <button className='btn btn-add-tgplus' onClick={toggleModal}>
                  Create New
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-8 my-auto'>
              <div className='head-tab'>
                <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link active'
                      id='pills-home-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-home'
                      type='button'
                      role='tab'
                      aria-controls='pills-home'
                      aria-selected='true'
                      onClick={() => setTab(0)}
                    >
                      TG Plus Subscriptions
                    </button>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link '
                      id='pills-home-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-user'
                      type='button'
                      role='tab'
                      aria-controls='pills-user'
                      aria-selected='true'
                      onClick={() => setTab(1)}
                    >
                      TG Plus User
                    </button>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-profile'
                      type='button'
                      role='tab'
                      aria-controls='pills-profile'
                      aria-selected='false'
                      onClick={() => setTab(2)}
                    >
                      API
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link'
                      id='pills-profile-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-provider'
                      type='button'
                      role='tab'
                      aria-controls='pills-provider'
                      aria-selected='false'
                      onClick={() => setTab(3)}
                    >
                      Solar Providers
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-4 text-md-end'>
              {tab != 3 && (
                <button className='btn btn-add-tgplus' onClick={toggleModal}>
                  Create New
                </button>
              )}
              {tab === 3 && (
                <button className='btn btn-add-tgplus' onClick={toggle2Modal}>
                  Create New
                </button>
              )}
            </div>
          </div>
          {tab === 0 && (
            <div className='card border-0'>
              <div className='card-header border-0 px-0'>
                <div className='row mt-2'>
                  <div className='col-12 col-md-8'>
                    <SearchInput
                      onSearch={handleSearch}
                      placeholder={'Search by MSISDN only'}
                      searchRequest={searchRequest}
                      searchOptionRequest={searchOptionRequest}
                      searchReturnResult={searchReturnResult}
                      cancelSearch={cancelSearch}
                    ></SearchInput>
                  </div>
                  <div className='col-6 col-md-2 dropdown'>
                    <button
                      className='btn btn-filter dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z'
                          fill='#868484'
                        />
                        <path
                          d='M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z'
                          fill='#868484'
                        />
                      </svg>
                      Filters
                    </button>
                    <div className='dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown'>
                      <div className='dropdown-item'>
                        <form action=''>
                          <div className='form-group'>
                            <label htmlFor=''>Sort List</label>
                            <select
                              id=''
                              className='form-control'
                              onChange={(e) => {
                                setSortOrder(e.target.value)
                              }}
                            >
                              <option value='DESC'>Descending Order</option>
                              <option value='ASC'>Ascending Order</option>
                            </select>
                          </div>

                          <div className='form-group mt-3'>
                            <label htmlFor=''>Status</label>
                            <select
                              id=''
                              className='form-control'
                              onChange={(e) => {
                                setStatus(e.target.value)
                              }}
                            >
                              <option value='ALL'>All</option>
                              <option value='PENDING_APPROVAL'>Pending Approval</option>
                              <option value='APPROVED'>Approved</option>
                              <option value='REJECTED'>Rejected</option>
                              <option value='INSTALLATION_COMPLETED'>Installation Completed</option>
                              <option value='ENDED'>Ended</option>
                            </select>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-6'>
                              <button
                                type='button'
                                className='btn btn-reset'
                                onClick={() => {
                                  setSortOrder('DESC')
                                  setStatus('ALL')
                                }}
                              >
                                Reset
                              </button>
                            </div>
                            <div className='col-md-6 text-end'>
                              <button
                                type='button'
                                className='btn btn-filter'
                                onClick={() => setSortOrder('DESC')}
                              >
                                Filter
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 col-md-2 dropdown'>
                    <button
                      className='btn btn-export dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9 11V17L11 15'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 17L7 15'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>{' '}
                      Export
                    </button>

                    <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('application/pdf')}> Export as .pdf</li>  */}
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/csv')}> Export as .csv</li> */}
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/doc')}> Export as .doc </li>  */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>User</td>
                        <td>MSISDN</td>
                        <td>Station Name</td>
                        <td>Number of Pumps</td>
                        <td>Capacity</td>
                        <td>Status</td>
                        <td>Due Date</td>
                        {/* <td>Date</td> */}
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading === true && subscriptions.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            <HorizontalLoader></HorizontalLoader>
                          </td>
                        </tr>
                      )}
                      {subscriptions.length > 0 &&
                        subscriptions.map((data: tgplusModel, index: Key) => (
                          <tr key={index}>
                            <td>
                              {pageNumber * paginationData.pageSize +
                                Number(index) +
                                1 -
                                paginationData.pageSize}
                            </td>
                            <td>
                              {data.customer.firstName} {data.customer.lastName}
                            </td>
                            <td>{data.customer.msisdn}</td>
                            <td>{data.stationName}</td>
                            <td>{data.noOfPumps}</td>
                            <td>{data.solarPowerCapacity ?? data.solarPowerCapacity}</td>
                            <td>
                              <span
                                className={
                                  ['APPROVED', 'INSTALLATION_COMPLETED', 'ACTIVE'].includes(
                                    data?.status,
                                  )
                                    ? 'status-success text-dark ms-2'
                                    : 'status-danger'
                                }
                              >
                                {formatString(data.status)}
                              </span>
                            </td>
                            <td>
                              {['INSTALLATION_COMPLETED', 'ACTIVE'].includes(data?.status)
                                ? getCountdownStatus(data.nextSubscriptionPaymentDueDate)
                                : '-'}
                            </td>
                            {/* <td>{TimeConverter(data.createdAt)}</td> */}
                            <td>
                              {' '}
                              <Link to={`/tgplus-subscription/${data.id}`}>
                                {/* <Link to={`/tgplus-stations-subscription/${data.customer.msisdn}`}> */}
                                <button className='btn btn-sm'>
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 25 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M22.2188 9.14969C19.9088 5.51969 16.5287 3.42969 12.9688 3.42969C11.1888 3.42969 9.45875 3.94969 7.87875 4.91969C6.29875 5.89969 4.87875 7.32969 3.71875 9.14969C2.71875 10.7197 2.71875 13.2697 3.71875 14.8397C6.02875 18.4797 9.40875 20.5597 12.9688 20.5597C14.7487 20.5597 16.4788 20.0397 18.0588 19.0697C19.6388 18.0897 21.0588 16.6597 22.2188 14.8397C23.2188 13.2797 23.2188 10.7197 22.2188 9.14969ZM12.9688 16.0397C10.7288 16.0397 8.92875 14.2297 8.92875 11.9997C8.92875 9.76969 10.7288 7.95969 12.9688 7.95969C15.2087 7.95969 17.0088 9.76969 17.0088 11.9997C17.0088 14.2297 15.2087 16.0397 12.9688 16.0397Z'
                                      fill='#FC6FBB'
                                    />
                                    <path
                                      d='M12.9687 9.13965C11.3987 9.13965 10.1187 10.4196 10.1187 11.9996C10.1187 13.5696 11.3987 14.8496 12.9687 14.8496C14.5387 14.8496 15.8287 13.5696 15.8287 11.9996C15.8287 10.4296 14.5387 9.13965 12.9687 9.13965Z'
                                      fill='#FC6FBB'
                                    />
                                  </svg>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}

                      {loading === false && subscriptions.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No subscription found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {paginationData.total >= paginationData.pageSize && (
                  <div className='pagination w-100 d-flex justify-content-center'>
                    {paginationData.total && (
                      <Pagination
                        className='pagination-bar ps-0'
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {tab === 1 && (
            <div className='card border-0'>
              <div className='card-header border-0 px-0'>
                <div className='row mt-2 d-none'>
                  <div className='col-12 col-md-8 '>
                    <SearchInput
                      onSearch={() => {
                        return
                      }}
                      placeholder={'Search Subscription No or User'}
                    ></SearchInput>
                  </div>
                  <div className='col-6 col-md-2 dropdown'>
                    <button
                      className='btn btn-filter dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z'
                          fill='#868484'
                        />
                        <path
                          d='M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z'
                          fill='#868484'
                        />
                      </svg>
                      Filters
                    </button>
                    <div className='dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown'>
                      <div className='dropdown-item'>
                        <form action=''>
                          <div className='form-group'>
                            <label htmlFor=''>Sort List</label>
                            <select
                              id=''
                              className='form-control'
                              onChange={(e) => {
                                setSortOrder(e.target.value)
                              }}
                            >
                              <option value='DESC'>Descending Order</option>
                              <option value='ASC'>Ascending Order</option>
                            </select>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-6'>
                              <button
                                type='button'
                                className='btn btn-reset'
                                onClick={() => setSortOrder('DESC')}
                              >
                                Reset
                              </button>
                            </div>
                            <div className='col-md-6 text-end'>
                              <button
                                type='button'
                                className='btn btn-filter'
                                onClick={() => setSortOrder('DESC')}
                              >
                                Filter
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 col-md-2 dropdown'>
                    <button
                      className='btn btn-export dropdown-toggle'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9 11V17L11 15'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 17L7 15'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
                          stroke='#868484'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>{' '}
                      Export
                    </button>

                    <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('application/pdf')}> Export as .pdf</li>  */}
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/csv')}> Export as .csv</li> */}
                      {/* <li className='dropdown-item' onClick={()=>exportTransactions('text/doc')}> Export as .doc </li>  */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>User</td>
                        <td>Phone Number</td>
                        <td>Number of Stations</td>
                        <td>Total Capacity</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingUser === true && order.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            <HorizontalLoader></HorizontalLoader>
                          </td>
                        </tr>
                      )}

                      {order.length > 0 &&
                        order.map((data: CustomerType, index: Key) => (
                          <tr key={index}>
                            <td>
                              {userPageNumber * userPaginationData.pageSize +
                                Number(index) +
                                1 -
                                userPaginationData.pageSize}
                            </td>
                            <td>
                              {data.firstName} {data.lastName}
                            </td>
                            <td>{data.msisdn}</td>
                            <td>-</td>
                            {/* <td>10Kva</td> */}
                            <td>-</td>

                            <td>
                              <td className='dropdown option-dropdown'>
                                <button
                                  className='btn border-0 dropdown-toggle'
                                  data-bs-toggle='dropdown'
                                  aria-expanded='false'
                                  data-bs-popper-config='{"strategy":"fixed"}'
                                >
                                  <svg
                                    width='18'
                                    height='18'
                                    viewBox='0 0 18 18'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z'
                                      fill='#707070'
                                    />
                                    <path
                                      d='M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z'
                                      fill='#707070'
                                    />
                                    <path
                                      d='M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z'
                                      fill='#707070'
                                    />
                                  </svg>
                                </button>

                                <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                                  <li className='dropdown-item'>
                                    <Link to={`/tgplus-stations/${data.msisdn}`}>
                                      View Stations
                                    </Link>
                                  </li>

                                  <li className='dropdown-item'>
                                    <Link to={`/tgplus-referals/${data.id}`}>View Referals</Link>
                                  </li>
                                </ul>
                              </td>
                            </td>
                          </tr>
                        ))}

                      {loadingUser === false && order.length === 0 && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            No transaction found
                          </td>
                        </tr>
                      )}

                      {loadingUser === true && (
                        <tr>
                          <td colSpan={8} className='text-center'>
                            <HorizontalLoader></HorizontalLoader>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {userPaginationData.total >= userPaginationData.pageSize && (
                  <div className='pagination w-100 d-flex justify-content-center'>
                    {userPaginationData.total && (
                      <Pagination
                        className='pagination-bar ps-0'
                        currentPage={Number(userPaginationData.pageNumber)}
                        totalCount={userPaginationData.total}
                        pageSize={userPaginationData.pageSize}
                        onPageChange={(userPage: React.SetStateAction<number>) => {
                          setUserPageNumber(userPage)
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {tab === 2 && (
            <div className='card border-0 api-card mt-3'>
              <div className='card-body border-0 px-0'>Coming Soon!</div>
            </div>
          )}

          {tab === 3 && (
            <div className='card border-0 mt-3'>
              <div className='card-body border-0 px-3'>
                <div className='table-responsive'>
                  <table className='table' style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>Business Name</td>
                        <td>Date Added</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {solarLoading === true && !solarSubscriptions && (
                        <tr>
                          <td colSpan={4} className='text-center'>
                            <HorizontalLoader></HorizontalLoader>
                          </td>
                        </tr>
                      )}
                      {solarSubscriptions &&
                        solarSubscriptions.length > 0 &&
                        solarSubscriptions.map(
                          (
                            data: {
                              id: string
                              businessName: string
                              createdAt: string
                              balance: string
                            },
                            index: Key,
                          ) => (
                            <tr
                              key={index}
                              onClick={() => {
                                navigate(
                                  `/solar-providers-stations/${data.id}/${data.businessName}/${data.balance}/${
                                    data.id
                                  }`,
                                )
                              }}
                            >
                              <td>{Number(index) + 1}</td>
                              <td>{data.businessName}</td>
                              <td>{data.createdAt}</td>
                              <td>
                                <Link
                                  to={`/solar-providers-stations/${data.id}/${data.businessName}/${data.balance}/${
                                    data.id
                                  }`}
                                >
                                  View Stations
                                </Link>
                              </td>
                            </tr>
                          ),
                        )}

                      {solarLoading === false && !solarSubscriptions && (
                        <tr>
                          <td colSpan={4} className='text-center'>
                            No provider found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ModalWrapper
        showModal={showModal}
        closeModal={toggleModal}
        title='Create TG Plus Subscription'
      >
        <CreateSubscription toggleModal={toggleModal}></CreateSubscription>
      </ModalWrapper>

      <ModalWrapper showModal={show2Modal} closeModal={toggle2Modal} title='Add New Provider'>
        <CreateSolarProvider
          toggleModal={() => {
            toggle2Modal()
            getSolarProviders()
          }}
        ></CreateSolarProvider>
      </ModalWrapper>
    </div>
  )
}

export default TGPlusSubscription
