import { Logger } from 'Helpers/Logger'
import { TimeConverter } from 'Helpers/TimeConverter'
import { tgplusModel } from 'Models'
import tradegridPlusService from 'Services/tradegridPlusService'
import { BreadCrumb, HorizontalLoader, Pagination } from 'Components'
import React, { Key, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './TGPlusSubscription.scss'
import { CurrencyFormatter } from 'Helpers'
import Swal from 'sweetalert2'

const TGPlusSolarProvidersStations = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortOrder, setSortOrder] = useState('DESC')
  const [status, setStatus] = useState('ALL')
  const [name, setName] = useState<string>()

  const { customerMsisdn, businessName, balance, solarProviderAccountId } = useParams<{
    customerMsisdn: string | undefined
    businessName: string | undefined
    balance: string | undefined
    solarProviderAccountId: string | undefined
  }>()

  const [order, setOrder] = useState<tgplusModel[]>([])
  const [loading, setLoading] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getTransaction = async () => {
    setLoading(true)

    await tradegridPlusService
      .solarProviderStations(pageNumber, sortOrder, status, customerMsisdn)
      .then(
        (response) => {
          setLoading(false)
          setOrder(response?.data.data.dataset)
          console.log('solarProviderStations', response?.data.data.dataset)

          setPaginationData({
            pageNumber: response?.data.data.pageNumber,
            pageSize: response?.data.data.pageSize,
            total: response?.data.data.total,
          })
        },
        (error: { response: object }) => {
          setLoading(false)
          Logger('response error sub', error.response)
        },
      )
  }

  useEffect(() => {
    customerMsisdn && getTransaction()
  }, [sortOrder, pageNumber, customerMsisdn])

  function formatString(input: string) {
    const lowerCaseString = input.toLowerCase()

    // Remove underscores
    const formattedString = lowerCaseString.replace(/_/g, ' ')

    // capitalize status
    const capitalizedString = formattedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedString
  }

  const getCountdownStatus = (targetDateStr: string) => {
    const targetDate = new Date(targetDateStr).getTime()
    const now = new Date().getTime()
    const timeLeft = targetDate - now

    if (timeLeft <= 0) {
      return <span style={{ color: 'red', fontWeight: 'bold' }}>Overdue</span>
    }

    const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24))

    return <span>{daysLeft} days left</span>
  }

  const confirmWithdrawal = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action is not reversible, Are you sure you want to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#186AFF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, withdraw!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we process your request.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })

        await tradegridPlusService
          .widthdrawWallet({ amountMajor: Number(balance), solarProviderAccountId })
          .then(
            () => {
              Swal.fire('Done!', 'Withdrawal request submitted successfully', 'success')
            },
            (error: { response: { data: { error: string } } }) => {
              console.log('error', error.response)

              Swal.fire({
                position: 'center',
                icon: 'warning',
                text: error.response.data.error,
                showConfirmButton: false,
                timer: 5000,
              })
            },
          )
      }
    })
  }
  return (
    <div className='TGPlusSubscription TGPlusSubscriptionDetails tgplus-stations-subscription'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={businessName ?? ''}
            parent={'Tradegrid Plus'}
            parentUrl={'tgplus-subscription'}
            showTitle={false}
          ></BreadCrumb>
          <div className='row mt-3'>
            <h6 className='col-md'>All {businessName ?? ''} Stations</h6>
            <div className='col-md text-md-end wallet-col' onClick={confirmWithdrawal}>
              <svg
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='me-2'
              >
                <path
                  d='M13 9H7'
                  stroke='#868484'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z'
                  stroke='#868484'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z'
                  stroke='#868484'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <span className='label'>Wallet Balance </span>- N
              {CurrencyFormatter(Number(balance ?? 0))}
            </div>

            <div className='col-md-1 text-md-end'>
              <button className='btn btn-default' onClick={confirmWithdrawal}>
                Withdraw
              </button>
            </div>
          </div>

          <div className='row mt-2'>
            <div className='card border-0'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <td>#</td>
                        <td>User</td>
                        <td>Station Name</td>
                        <td>MSISDN</td>
                        <td>Capacity</td>
                        <td>Monthly Payment</td>
                        <td>Status</td>
                        <td>Due Date</td>
                        <td>Date</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {loading === true && (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <HorizontalLoader></HorizontalLoader>
                          </td>
                        </tr>
                      )}

                      {order.length > 0 &&
                        order.map((data: tgplusModel, index: Key) => (
                          <tr key={index}>
                            <td>{Number(index) + 1}</td>
                            <td>
                              {data.customer.firstName} {data.customer.lastName}
                            </td>
                            <td>{data.stationName}</td>
                            <td>{data.customer.msisdn}</td>
                            <td>{data.solarPowerCapacity ?? data.solarPowerCapacity}</td>
                            <td>₦{CurrencyFormatter(data.monthlySubscriptionMajor)}</td>
                            <td>
                              <span
                                className={
                                  ['APPROVED', 'INSTALLATION_COMPLETED', 'ACTIVE'].includes(
                                    data?.status,
                                  )
                                    ? 'status-success text-dark ms-2'
                                    : 'status-danger'
                                }
                              >
                                {formatString(data.status)}
                              </span>
                            </td>
                            <td>
                              {['INSTALLATION_COMPLETED', 'ACTIVE'].includes(data?.status)
                                ? getCountdownStatus(data.nextSubscriptionPaymentDueDate)
                                : '-'}
                            </td>
                            <td>{TimeConverter(data.createdAt)}</td>
                            <td>
                              {' '}
                              <Link to={`/tgplus-subscription/${data.id}`}>
                                {/* <Link to={`/tgplus-stations-subscription/${data.customer.msisdn}`}> */}
                                <button className='btn btn-sm'>
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 25 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M22.2188 9.14969C19.9088 5.51969 16.5287 3.42969 12.9688 3.42969C11.1888 3.42969 9.45875 3.94969 7.87875 4.91969C6.29875 5.89969 4.87875 7.32969 3.71875 9.14969C2.71875 10.7197 2.71875 13.2697 3.71875 14.8397C6.02875 18.4797 9.40875 20.5597 12.9688 20.5597C14.7487 20.5597 16.4788 20.0397 18.0588 19.0697C19.6388 18.0897 21.0588 16.6597 22.2188 14.8397C23.2188 13.2797 23.2188 10.7197 22.2188 9.14969ZM12.9688 16.0397C10.7288 16.0397 8.92875 14.2297 8.92875 11.9997C8.92875 9.76969 10.7288 7.95969 12.9688 7.95969C15.2087 7.95969 17.0088 9.76969 17.0088 11.9997C17.0088 14.2297 15.2087 16.0397 12.9688 16.0397Z'
                                      fill='#FC6FBB'
                                    />
                                    <path
                                      d='M12.9687 9.13965C11.3987 9.13965 10.1187 10.4196 10.1187 11.9996C10.1187 13.5696 11.3987 14.8496 12.9687 14.8496C14.5387 14.8496 15.8287 13.5696 15.8287 11.9996C15.8287 10.4296 14.5387 9.13965 12.9687 9.13965Z'
                                      fill='#FC6FBB'
                                    />
                                  </svg>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}

                      {loading === false && order.length === 0 && (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            No station found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {paginationData.total >= paginationData.pageSize && (
                  <div className='pagination w-100 d-flex justify-content-center d-none'>
                    {paginationData.total && (
                      <Pagination
                        className='pagination-bar ps-0'
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* {order.length > 0 &&
              order.map((data: tgplusModel, index: Key) => (
                <div className='col-xl-6' key={index}>
                  <div className='card border-0 mb-3'>
                    <div className='card-header bg-white py-3'>
                      <div className='row'> 
                        <div className='col subDate'>
                          
                          {TimeConverter(data.createdAt)}
                        </div>

                        <div className='col text-md-end mt-2 mt-md-0'>
                          <span
                            className={
                              ['APPROVED', 'INSTALLATION_COMPLETED'].includes(data.status)
                                ? 'status-success'
                                : 'status-danger'
                            }
                          >
                            {data.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='capacity'>
                            {data.solarPowerCapacity ?? 0}kva Solar Capacity
                          </div>
                        </div>
                        <div className='col-md-6 text-lg-end'>
                          <div className='capacity-price'>
                            N{CurrencyFormatter(data.monthlySubscriptionMajor ?? 0)}/m
                          </div>
                        </div>
                        <div className='col-md-12 station-details  mt-3'>
                          {data.stationName} | {data.stationAddress}. {data.stationState}.
                        </div>
                        <div className='col-md-12 next-due-date  mt-3'>
                          Next Due Date {TimeConverter(data.nextSubscriptionPaymentDueDate)}
                        </div>
                      </div>
                    </div>
                    <div className='card-footer bg-white text-center py-3'>
                      <Link to={`/tgplus-subscription/${data.id}`}>View Details</Link>
                    </div>
                  </div>
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TGPlusSolarProvidersStations
