import React, { FC, useEffect, useState } from 'react'
import { CustomerType } from 'Models'
import { ShowTGAlert } from 'Helpers/ShowTGAlert'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { urlencodePhoneNumber } from 'Helpers'
import customerService from 'Services/customerService'
import './NotificationModal.scss'

interface propsType {
  toggleLoanProviderModal: () => void
  userData?: CustomerType
}

const schema = yup.object().shape({
  tag: yup.string().required(),
})

const NotificationModal: FC<propsType> = ({ toggleLoanProviderModal, userData }) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(true)

  const { showAlert } = ShowTGAlert()
  const [request, setRequest] = useState(false)

  const submitNewUserInformation = async () => {
    setRequest(true)
    const encodedPhone = urlencodePhoneNumber(userData?.msisdn)

    await customerService.setNotification(encodedPhone, notificationsEnabled).then(
      () => {
        setRequest(false)
        toggleLoanProviderModal()

        showAlert({
          message: `Notification ${notificationsEnabled ? 'ENABLED' : 'DISABLED'}`,
          show: true,
          title: 'Success',
          type: 'success',
        })
      },
      (error: { response: { data: { error: string } } }) => {
        setRequest(false)
        toggleLoanProviderModal()
        showAlert({ message: error.response.data.error, show: true, title: 'Error', type: 'error' })
      },
    )
  }

  useEffect(() => {
    if (userData) setNotificationsEnabled(userData?.notificationsDisabled)
  }, [userData])

  return (
    <div className='AddUser'>
      <form onSubmit={submitNewUserInformation}>
        {/* Enable/Disable Notifications Toggle Switch */}
        <div className='form-group mb-3 d-flex align-items-center'>
          <label className='me-2'>Enable Notifications</label>
          <div className='form-check form-switch notification-switch'>
            <input
              type='checkbox'
              className='form-check-input'
              role='switch'
              id='notificationSwitch'
              checked={notificationsEnabled}
              onChange={() => setNotificationsEnabled(!notificationsEnabled)}
            />
          </div>
        </div>

        <div className='form-group mb-3'>
          <button
            type='submit'
            className='btn btn-default w-100'
            disabled={request}
            onClick={submitNewUserInformation}
          >
            Save Settings {request && <span className='spinner-border spinner-border-sm'></span>}
          </button>
        </div>
      </form>
    </div>
  )
}

export default NotificationModal
