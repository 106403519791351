import { BreadCrumb, HorizontalLoader } from 'Components'
import tradegridPlusService from 'Services/tradegridPlusService'
import React, { Key, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './TGPlusSubscriptionDetails.scss'
import { CurrencyFormatter } from 'Helpers'
import { tgplusModel } from 'Models'

export interface subscriptionTransactionType {
  id: string
  accountId: string
  customer: {
    firstName: string
    lastName: string
    msisdn: string
    emailAddress: string
    photo: {
      url: string
    }
  }
  type: string
  flowType: string
  description: string
  paidStatus: string
  amountMajor: number
  currency: string
  walletBalanceMajorBefore: number
  walletBalanceMajorAfter: number
  metadata: {
    tradegridPlusId: string
  }
  createdAt: string
}

const TGPlusSubscriptionTransaction = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const [loading, setLoading] = useState(false)

  const [subscriptionTransaction, setSubscriptionTransaction] =
    useState<subscriptionTransactionType[]>()

  const [transaction, setTransaction] = useState<tgplusModel>()

  const [pageNumber, setPageNumber] = useState(1)

  const [solarPaginationData, setSolarPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getTransaction = async () => {
    setLoading(true)

    await tradegridPlusService.subscriptionTransaction(subscriptionId, pageNumber, 'customer').then(
      (response) => {
        setLoading(false)
        console.log('solarProviders transaction', response?.data.data)
        setSubscriptionTransaction(response?.data.data)

        setSolarPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: object }) => {
        setLoading(false)
      },
    )
  }

  useEffect(() => {
    getTransaction()
  }, [subscriptionId, pageNumber])

  const getSubscription = async () => {
    setLoading(true)
    await tradegridPlusService.single(1, subscriptionId).then(
      (response) => {
        setLoading(false)
        setTransaction(response?.data.data.dataset[0])
        console.log('response transactionService', response)
      },
      (error: { response: object }) => {
        setLoading(false)
      },
    )
  }

  useEffect(() => {
    getSubscription()
  }, [subscriptionId])

  return (
    <div className='TGPlusSubscriptionDetails'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={'Subscription Details'}
            parent={'Tradegrid Plus'}
            parentUrl={`tgplus-subscription/${subscriptionId}`}
            showTitle={false}
          ></BreadCrumb>
          <div className='card border-0 mt-3 transaction'>
            <div className='card-body border-0 px-3'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Customer</th>
                      <th>MSISDN</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Amount</th>
                      {/* <th>Wallet Balance Before</th>
                      <th>Wallet Balance After</th> */}
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan={13} className='text-center'>
                          <HorizontalLoader />
                        </td>
                      </tr>
                    )}
                    {!loading &&
                      subscriptionTransaction &&
                      subscriptionTransaction.length > 0 &&
                      subscriptionTransaction.map((data, index) => (
                        <tr key={data.id}>
                          <td>{index + 1}</td>
                          <td>{`${transaction?.customer.firstName} ${transaction?.customer.lastName}`}</td>
                          <td>{transaction?.customer.msisdn}</td>
                          <td>{data.flowType === 'out' ? 'Debit' : 'Credit'}</td>
                          <td>{data.description}</td>
                          <td>{data.paidStatus}</td>
                          <td>
                            {data.currency}
                            {CurrencyFormatter(data.amountMajor)}
                          </td>
                          {/* <td>
                            {data.currency}
                            {CurrencyFormatter(data.walletBalanceMajorBefore)}
                          </td>
                          <td>
                            {data.currency}
                            {CurrencyFormatter(data.walletBalanceMajorAfter)}
                          </td> */}
                          <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    {!loading &&
                      subscriptionTransaction &&
                      subscriptionTransaction.length === 0 && (
                        <tr>
                          <td colSpan={13} className='text-center'>
                            No transaction found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TGPlusSubscriptionTransaction
