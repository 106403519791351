import React, { ChangeEvent, Key, useEffect, useState } from 'react'
import './CityLaunchers.scss'
import { Link, useNavigate } from 'react-router-dom'
import { HorizontalLoader, ModalWrapper, Pagination } from '../../../../Components'
import AddUser from '../../Components/User/AddUser/AddUser'
import customerService from '../../../../Services/customerService'
import { CustomerType } from '../../../../Models'
import { Logger } from '../../../../Helpers/Logger'
import SearchInput from '../../../../Components/SearchInput/SearchInput'
import { CurrencyFormatter, TableSerialNumber, checkSearchInputType } from '../../../../Helpers'
import { EditUserPlan, LoanProviderModal, TagUsersModal } from '../../Components'
import { locationService } from '../../../../Services/locationService'
import { ShowTGAlert } from '../../../../Helpers/ShowTGAlert'
import { countryType } from '../DeliveryUltra/DeliveryUltra'
import MultiPLPModal from '../../Components/User/MultiPLPModal/MultiPLPModal'
import { CityLauncherMoel } from 'Models/cityLauncers'
import { TimeConverter } from 'Helpers/TimeConverter'
import Swal from 'sweetalert2'

const CityLaunchers = () => {
  const [showModal, setShowModal] = useState(false)

  const { showAlert } = ShowTGAlert()

  const toggleModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const [showEditModal, setShowEditModal] = useState(false)

  const toggleEditModal = () => {
    setShowEditModal((prevState) => !prevState)
  }

  const [showMultiPLPModal, setShowMultiPLPModal] = useState(false)

  const toggleMultiPLPModal = () => {
    setShowMultiPLPModal((prevState) => !prevState)
  }

  const [showLoanProviderModal, setShowLoanProviderModal] = useState(false)

  const toggleLoanProviderModal = () => {
    setShowLoanProviderModal((prevState) => !prevState)
  }

  const [showTagLoanProviderModal, setShowTagLoanProviderModal] = useState(false)

  const toggleTagLoanProviderModal = () => {
    setShowTagLoanProviderModal((prevState) => !prevState)
    getUsers()
  }

  const [sortOrder, setSortOrder] = useState('DESC')
  const [tradersCardNumberStatus, setTradersCardNumberStatus] = useState<boolean | undefined>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageNumber, setPage] = useState(1)
  const [customers, setCustomers] = useState<CityLauncherMoel[]>()
  const [loading, setLoading] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchReturnResult, setSearchReturnResult] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const [editData, setEditData] = useState<CustomerType>()
  const [multiPLPData, setMultiPLPData] = useState<CustomerType>()
  const [loanProviderData, setLoanProviderData] = useState<CustomerType>()

  const [countries, setCountries] = useState<Array<countryType>>([])

  const getUsers = async () => {
    setLoading(true)
    setSearchReturnResult(false)
    await customerService.cityLaunchers(pageNumber, sortOrder).then(
      (response) => {
        setLoading(false)

        const dataSet = response?.data.data.dataset
        setCustomers(dataSet)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })

        dataSet.length > 0 && setSearchReturnResult(true)
      },
      (error: { response: { data: { error: string } } }) => {
        showAlert({ message: error.response.data.error, show: true, title: 'Error', type: 'error' })

        setSearchReturnResult(false)
        setLoading(false)
      },
    )
  }

  useEffect(() => {
    getUsers()
  }, [pageNumber, sortOrder])

  // get locations
  const getLocations = async () => {
    await locationService.index().then((response) => {
      setCountries(response.data.data)
    })
  }

  useEffect(() => {
    getLocations()
  }, [])

  const navigate = useNavigate()

  const deleteLaunch = (id: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete a city launcher, this action is not reversible. Are you sure you want to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#186AFF',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        await customerService.cityLaunchersDelete(id).then(
          () => {
            setLoading(false)
            Swal.fire('Done!', 'Deleted succesfully', 'success')
            getUsers()
          },
          (error: { response: { data: { error: string } } }) => {
            console.log('error', error.response)
            setLoading(false)
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: error.response.data.error,
              showConfirmButton: false,
              timer: 5000,
            })
          },
        )
      }
    })
  }

  const handleSearch = (searchTerm: string) => {
    return
  }

  return (
    <div className='CityLaunchers'>
      <div className='container mt-4'>
        <div className='container'>
          <div className='card border-0'>
            <div className='card-header border-0 px-0'>
              <div className='row'>
                <div className='col-6 col-md-6 my-auto'>
                  <div className='title'>{paginationData.total} total applications</div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Fullname</td>
                      <td>Phone Number</td>
                      <td>Email</td>
                      <td>School</td>
                      <td>Department</td>
                      <td>Date Joined</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {customers &&
                      customers.length > 0 &&
                      customers.map((data: CityLauncherMoel, index: Key) => (
                        <tr key={index}>
                          <td
                            onClick={() => {
                              navigate(`/city-launchers/${data.phoneNumber}`)
                            }}
                            className='accountIdCol'
                          >
                            {TableSerialNumber(
                              pageNumber,
                              paginationData.pageSize,
                              parseInt(index.toString()),
                            )}
                          </td>
                          <td
                            onClick={() => {
                              navigate(`/city-launchers/${data.phoneNumber}`)
                            }}
                          >
                            {data.firstName} {data.lastName}
                          </td>
                          <td>{data.phoneNumber}</td>
                          <td>{data.email}</td>
                          <td>{data.school}</td>
                          <td>{data.department}</td>
                          <td> {TimeConverter(data.createdAt)} </td>

                          <td className='dropdown option-dropdown'>
                            <button
                              className='btn border-0 dropdown-toggle'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                              data-bs-popper-config='{"strategy":"fixed"}'
                            >
                              <svg
                                width='18'
                                height='18'
                                viewBox='0 0 18 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z'
                                  fill='#707070'
                                />
                                <path
                                  d='M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z'
                                  fill='#707070'
                                />
                                <path
                                  d='M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z'
                                  fill='#707070'
                                />
                              </svg>
                            </button>

                            <ul className='dropdown-menu dropdown-menu-end export-dropdown border-0 shadow'>
                              <li>
                                <Link
                                  className='dropdown-item'
                                  to={`/city-launchers/${data.phoneNumber}`}
                                >
                                  Details
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className='dropdown-item'
                                  to={''}
                                  onClick={() => deleteLaunch(data.id)}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    {loading === false && customers && customers.length === 0 && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          No Application goun found
                        </td>
                      </tr>
                    )}
                    {loading === true && (
                      <tr>
                        <td colSpan={8} className='text-center'>
                          <HorizontalLoader></HorizontalLoader>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {paginationData.total >= paginationData.pageSize && (
                <div className='pagination w-100 d-flex justify-content-center'>
                  {paginationData.total && (
                    <Pagination
                      className='pagination-bar ps-0'
                      currentPage={Number(paginationData.pageNumber)}
                      totalCount={paginationData.total}
                      pageSize={paginationData.pageSize}
                      onPageChange={(page: React.SetStateAction<number>) => setPage(page)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper showModal={showModal} closeModal={toggleModal} title='Add New User'>
        <AddUser toggleModal={toggleModal}></AddUser>
      </ModalWrapper>

      <ModalWrapper
        showModal={showEditModal}
        closeModal={toggleEditModal}
        title='Edit User Account Plan'
      >
        <EditUserPlan
          toggleEditModal={toggleEditModal}
          countries={countries}
          editData={editData}
        ></EditUserPlan>
      </ModalWrapper>

      <ModalWrapper
        showModal={showMultiPLPModal}
        closeModal={toggleMultiPLPModal}
        title='Enable multiple PLP for a customer'
      >
        <MultiPLPModal
          toggleMultiPLPModal={toggleMultiPLPModal}
          userData={multiPLPData}
        ></MultiPLPModal>
      </ModalWrapper>

      <ModalWrapper
        showModal={showLoanProviderModal}
        closeModal={toggleLoanProviderModal}
        title='Assign a loan provider to a customer'
      >
        <LoanProviderModal
          toggleLoanProviderModal={toggleLoanProviderModal}
          userData={loanProviderData}
        ></LoanProviderModal>
      </ModalWrapper>

      <ModalWrapper
        showModal={showTagLoanProviderModal}
        closeModal={toggleTagLoanProviderModal}
        title='Tag a customer'
      >
        <TagUsersModal
          toggleLoanProviderModal={toggleTagLoanProviderModal}
          userData={loanProviderData}
        ></TagUsersModal>
      </ModalWrapper>
    </div>
  )
}

export default CityLaunchers
