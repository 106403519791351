import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'
import { UpdateUserPlanFormType } from '../Pages/Frontend/Components/User/EditUserPlan/EditUserPlan'
import { addCustomerFormType } from '../Pages/Frontend/Components/User/AddUser/AddUser'

AxiosInterceptor()

const customerService = {
  index: async (
    pageNumber: number,
    sortOrder: string,
    loanProvider?: string,
    tradersCardNumberStatus?: boolean,
    notificationsDisabled?: boolean,
  ) => {
    // Initialize query parameters
    const params: Record<string, any> = {
      pageNumber,
      sortOrder,
    }

    // Conditionally add parameters if they are defined
    if (loanProvider) params.loanProvider = loanProvider
    if (tradersCardNumberStatus !== undefined)
      params.hasPlpTradersCardNumber = tradersCardNumberStatus
    if (notificationsDisabled !== undefined) params.notificationsDisabled = notificationsDisabled

    // Construct the query string
    const queryString = new URLSearchParams(params).toString()

    // Make the API request
    return await axios.get(`/customers?${queryString}`)
  },

  // index: async (
  //   pageNumber: number,
  //   sortOrder: string,
  //   loanProvider?: string | undefined,
  //   tradersCardNumberStatus?: boolean | undefined,
  //   notificationsDisabled?: boolean | undefined,
  // ) => {
  //   if (!loanProvider) {
  //     if (tradersCardNumberStatus !== undefined) {
  //       return await axios.get(
  //         `/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&hasPlpTradersCardNumber=${tradersCardNumberStatus}&notificationsDisabled=${notificationsDisabled}`,
  //       )
  //     }
  //     return await axios.get(
  //       `/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&notificationsDisabled=${notificationsDisabled}`,
  //     )
  //   } else {
  //     if (tradersCardNumberStatus !== undefined) {
  //       return await axios.get(
  //         `/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&loanProvider=${loanProvider}&hasPlpTradersCardNumber=${tradersCardNumberStatus}&notificationsDisabled=${notificationsDisabled}`,
  //       )
  //     }
  //     return await axios.get(
  //       `/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&loanProvider=${loanProvider}&notificationsDisabled=${notificationsDisabled}`,
  //     )
  //   }
  // },

  allCustomers: () => {
    return axios.get('/customers?pageNumber=1&sortOrder=DESC')
  },

  allReferrals: async (pageNumber: number, userId: string | undefined) => {
    return await axios.get(`/customers?pageNumber=${pageNumber}&sortOrder=DESC&referrals=${userId}`)
  },

  createNewCustomer: (payload: addCustomerFormType) => {
    return axios.post('/customers', payload)
  },

  searchCustomer: async (endpoint: string, pageNumber: number, sortOrder: string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(`customers?${queryParams}`)
  },

  updatePlan: (msisdn: string | undefined, payload: UpdateUserPlanFormType) => {
    return axios.put(`/customers/accountplan?msisdn=${msisdn}`, payload)
  },

  getDetails: (customerAccountId: string | undefined) => {
    return axios.get(`/customers?customerAccountId=${customerAccountId}&pageNumber=1`)
  },

  getUserLPs: (msisdn: string | undefined) => {
    return axios.get(`/customers/${msisdn}/loanproviders`)
  },

  updateMultiPlp: (msisdn: string | undefined, enableOrDisable: string) => {
    return axios.post(`/customers/${msisdn}/enableMultiplePLP/${enableOrDisable}`)
  },

  searchCustomerName: async (
    firstName: string | undefined,
    lastName: undefined | string,
    pageNumber: number,
    sortOrder: string,
  ) => {
    let url = 'customers'

    if (firstName) {
      url += `?firstName=${firstName}`
    }

    if (lastName) {
      url += firstName ? `&lastName=${lastName}` : `?lastName=${lastName}`
    }

    url += `&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(url)
  },

  refreshConsentLink: (msisdn: string | undefined, loanProvider: string | undefined) => {
    return axios.put(`/customers/${msisdn}/loanprovider/${loanProvider}/bvnConsentLink`)
  },

  reconcileAccount: (msisdn: string | undefined) => {
    return axios.get(`/loanProviders/reconcileVfdAccountWithTradegridWallet/${msisdn}`)
  },

  cityLaunchers: async (pageNumber: number, sortOrder: string) => {
    return axios.get(`/cityLauncherApplications?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },

  cityLaunchersDetails: async (msisdn: string | undefined) => {
    return axios.get(`/cityLauncherApplications?pageNumber=1&sortOrder=DESC&phoneNumber=${msisdn}`)
  },

  cityLaunchersDelete: async (id: string) => {
    return axios.delete(`/cityLauncherApplications/${id}`)
  },

  setNotification: async (msisdn: string | undefined, notificationsDisabled: boolean) => {
    return axios.put(`/customers/${msisdn}/notificationS/${notificationsDisabled}`)
  },
}

export default customerService
