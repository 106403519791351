import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'bootstrap/dist/css/bootstrap.min.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider, ProtectedAdmin } from './Components'
import {
  Company,
  CompanyProducts,
  Login,
  Logistics,
  Orders,
  Plp,
  ProductDetails,
  ProductScanned,
  Products,
  RequestDetails,
  Requests,
  Settings,
  Transactions,
  Users,
  UsersDetails,
  CompanyLocations,
  CompanyLocationsRates,
  OrderDetails,
  EditOrderDetails,
  PLPDetails,
  PLPTracking,
  DeliveryUltra,
  OrderTracking,
  LogisticsDetails,
  TripDetails,
  OrderLogistics,
  CreatePlp,
  LoanProviders,
  EditUsersDetails,
  CompanyDetails,
  AssignedCustomers,
  LoanProvidersDetails,
  LoanProviderTransaction,
  TransactionDetails,
  LoanProviderPOSTransaction,
  BulkSms,
  BulkSmsHistory,
  CreatePlpv2,
  BulkSmsHistoryDetails,
  LoanProviderAllPOSTransaction,
  ApproveWalletTransfer,
  TGPlusSubscriptionDetails,
  TGPlusSubscription,
  WebsiteControl,
  BankTransferRequestDetails,
  TGPlusReferals,
  TGPlusStations,
  TGPlusStationSubscription,
  TGPlusSolarProvidersStations,
  CityLaunchersDetails,
  CityLaunchers,
} from './Pages/Frontend/Interface/Index'

import PublicWrapper from 'Pages/Skeleton/PublicWrapper/PublicWrapper'
import { Provider } from 'react-redux'
import Store from './Storage/Store'
import { Auth0Provider } from '@auth0/auth0-react'
import { Auth0Config } from './Config/Auth0Config'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ConditionalRouting } from './ConditionalRouting'
import {
  AnnouncementDetails,
  MarketPrice,
  DepotPrice,
  AverageMarketPrice,
} from './Pages/Frontend/Components'
import TGPlusSubscriptionTransaction from './Pages/Frontend/Interface/TGPlusSubscription/TGPlusSubscriptionDetails/TGPlusSubscriptionTransaction'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
})

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={Store}>
        <BrowserRouter>
          <HelmetProvider>
            <Auth0Provider
              domain={Auth0Config.domain}
              clientId={Auth0Config.clientId}
              authorizationParams={Auth0Config.authorizationParams}
            >
              <AuthProvider>
                <Routes>
                  <Route path='login' element={<Login />} />

                  <Route path='' element={<ProtectedAdmin></ProtectedAdmin>}>
                    <Route index element={<ConditionalRouting></ConditionalRouting>} />
                    <Route path='dashboard' element={<ConditionalRouting></ConditionalRouting>} />
                    <Route path='company' element={<Company />} />
                    <Route path='company/products/:companyUuid' element={<CompanyProducts />} />
                    <Route path='company/locations/:companyUuid' element={<CompanyLocations />} />
                    <Route
                      path='company/locations/rates/:locationUuid'
                      element={<CompanyLocationsRates />}
                    />
                    <Route path='company/details/:uuid' element={<CompanyDetails />} />

                    <Route path='products' element={<Products />} />
                    <Route path='product/:productId' element={<ProductDetails />} />

                    <Route path='orders' element={<Orders />} />
                    <Route path='order/:referenceId' element={<OrderDetails />} />

                    <Route path='order-tracking/:referenceId' element={<OrderTracking />} />
                    <Route path='order-logistics/:referenceId' element={<OrderLogistics />} />

                    <Route path='order/edit/:referenceId' element={<EditOrderDetails />} />

                    <Route path='requests' element={<Requests />} />
                    <Route path='request/:referenceId' element={<RequestDetails />} />
                    <Route
                      path='bank-transfer-request/:referenceId'
                      element={<BankTransferRequestDetails />}
                    />
                    <Route path='plp' element={<Plp />} />
                    <Route path='plp/:referenceId' element={<PLPTracking />} />
                    <Route path='plp/detail/:referenceId' element={<PLPDetails />} />
                    <Route path='plp/create' element={<CreatePlp />} />

                    <Route path='transactions' element={<Transactions />} />
                    <Route path='transactions/:uuid' element={<TransactionDetails />} />

                    <Route path='settings' element={<Settings />} />

                    <Route path='web-control' element={<WebsiteControl />}>
                      <Route path='announcement' element={<AnnouncementDetails />} />
                      <Route path='average-market-price' element={<AverageMarketPrice />} />
                      <Route path='market-price' element={<MarketPrice />} />
                      <Route path='depot-price' element={<DepotPrice />} />
                    </Route>

                    <Route path='tgplus-subscription' element={<TGPlusSubscription />} />

                    <Route
                      path='tgplus-subscription/:subscriptionId'
                      element={<TGPlusSubscriptionDetails />}
                    />

                    <Route
                      path='tgplus-subscription/:subscriptionId/transaction'
                      element={<TGPlusSubscriptionTransaction />}
                    />

                    <Route path='tgplus-stations/:customerMsisdn' element={<TGPlusStations />} />

                    <Route
                      path='tgplus-stations-subscription/:customerMsisdn'
                      element={<TGPlusStationSubscription />}
                    />

                    <Route
                      path='solar-providers-stations/:customerMsisdn/:businessName/:balance/:solarProviderAccountId'
                      element={<TGPlusSolarProvidersStations />}
                    />

                    <Route path='city-launchers' element={<CityLaunchers />} />

                    <Route
                      path='city-launchers/:customerMsisdn'
                      element={<CityLaunchersDetails />}
                    />

                    <Route path='tgplus-referals/:userUuid' element={<TGPlusReferals />} />

                    <Route path='logistics' element={<Logistics />} />
                    <Route path='logistic/:truckId' element={<LogisticsDetails />} />
                    <Route path='logistic/truck/details/:referenceId' element={<TripDetails />} />

                    <Route path='users' element={<Users />} />
                    <Route path='user/:customerAccountId' element={<UsersDetails />} />
                    <Route path='user/edit/:customerAccountId' element={<EditUsersDetails />} />

                    <Route path='product-scanner' element={<ProductScanned />} />

                    <Route path='ultra-delivery' element={<DeliveryUltra />} />

                    <Route path='loan-providers' element={<LoanProviders />} />
                    <Route path='loan-providers/:loanProvider' element={<LoanProvidersDetails />} />
                    <Route
                      path='loan-providers/users/:loanProvider'
                      element={<AssignedCustomers />}
                    />
                    <Route
                      path='loan-providers/users/post-transaction/:loanProvider/:customerAccountId/:accountNumber'
                      element={<LoanProviderPOSTransaction />}
                    />
                    <Route
                      path='loan-providers/users/post-transaction/:loanProvider'
                      element={<LoanProviderAllPOSTransaction />}
                    />

                    <Route
                      path='loan-providers/transaction/:loanProvider'
                      element={<LoanProviderTransaction />}
                    />
                    <Route path='bulksms' element={<BulkSms />} />
                    <Route path='bulksms-history' element={<BulkSmsHistory />} />
                    <Route path='bulksms-history/:smsId' element={<BulkSmsHistoryDetails />} />

                    <Route
                      path='/transfer-approval/:loanProvider/:transferRequestId'
                      element={<ApproveWalletTransfer />}
                    />
                  </Route>

                  <Route
                    path='plp-application'
                    element={
                      <PublicWrapper external={false}>
                        <CreatePlp publicAccess={true} />
                      </PublicWrapper>
                    }
                  />
                  <Route
                    path='plp-application-vfd'
                    element={
                      <PublicWrapper external={true}>
                        <CreatePlpv2 publicAccess={true} />
                      </PublicWrapper>
                    }
                  />
                </Routes>
              </AuthProvider>
            </Auth0Provider>
          </HelmetProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
)
